import React from "react";
import { Typography, List, ListItem, ListItemText } from "@mui/material";
import { ReactComponent as RightArrow } from "../../../tools/icons/rightarrow.svg";

const SettingsContent = ({ designManager }) => {
  const listItemStyle = {
    display: "flex",
    justifyContent: "space-between",
    padding: "6px 12px",
    borderBottom: "0.2px solid #000",
    width: "95%",
    margin: "0 auto",
  };

  const firstItemStyle = {
    display: "flex",
    justifyContent: "space-between",
    padding: "6px 12px",
    borderBottom: "0.2px solid #000",
    width: "95%",
    margin: "0 auto",
    borderTop: "none", // Explicitly remove top border
  };

  const typographyStyle = {
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: designManager.getFieldValue("primaryThemeFont"),
    color: designManager.getFieldValue("primaryFontColor"),
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "40px",
          display: "flex", // Enable Flexbox
          alignItems: "center", // Vertically center
          justifyContent: "center", // Horizontally center
          backgroundColor: designManager.getFieldValue(
            "appHeaderBackgroundColor"
          ),
          textAlign: "center",
          marginTop: "15px",
          color: designManager.getFieldValue("appHeaderFontColor"),
          fontSize: "14px",
          fontFamily: designManager.getFieldValue("appHeaderFont"),
          zIndex: 2,
        }}
      >
        Settings
      </div>
      <div
        style={{
          width: "100%",
          height: "500px",
          backgroundColor: designManager.getFieldValue(
            "primaryBackgroundColor"
          ),

          fontSize: "14px",

          display: "flex", // Flexbox for centering
          flexDirection: "column", // Arrange content vertically
        }}
      >
        {/* Settings List */}
        <List
          style={{
            width: "100%",
            paddingTop: 10,
          }}
        >
          {/* Change Password */}
          <ListItem button style={firstItemStyle}>
            <ListItemText
              primary={<div style={typographyStyle}>Change Password</div>}
            />
            <RightArrow width="16px" height="16px" />
          </ListItem>

          {/* Update Personal Information */}
          <ListItem button style={listItemStyle}>
            <ListItemText
              primary={
                <div style={typographyStyle}>Update Personal Information</div>
              }
            />
            <RightArrow width="16px" height="16px" />
          </ListItem>

          {/* Deactivate & delete personal data */}
          <ListItem button style={listItemStyle}>
            <ListItemText
              primary={
                <div style={typographyStyle}>
                  Deactivate & delete personal data
                </div>
              }
            />
            <RightArrow width="16px" height="16px" />
          </ListItem>

          {/* Logout */}
          <ListItem button style={listItemStyle}>
            <ListItemText primary={<div style={typographyStyle}>Logout</div>} />
            <RightArrow width="16px" height="16px" />
          </ListItem>
        </List>
      </div>
    </div>
  );
};

export default SettingsContent;
