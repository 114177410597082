import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { SketchPicker } from "react-color";
import Styles from "../resources/styles";
import ColourBox from "./sharedcomponents/ColourBox";
import ImageBox from "./sharedcomponents/ImageBox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import { ApiServiceClass } from "../../../components/api-service";
import { toast } from "react-toastify";

function ColorsContent({ designManager }) {
  const [colorsContent, setColorsContent] = useState({
    appHeaderBackgroundColor:
      designManager.getCurrentDesign().appHeaderBackgroundColor,
    appHeaderFontColor: designManager.getCurrentDesign().appHeaderFontColor,
    primaryBackgroundColor:
      designManager.getCurrentDesign().primaryBackgroundColor,
    primaryFontColor: designManager.getCurrentDesign().primaryFontColor,
    navBarBackgroundColor:
      designManager.getCurrentDesign().navBarBackgroundColor,
    navBarSelectedColor: designManager.getCurrentDesign().navBarSelectedColor,
    navBarDeselectedColor:
      designManager.getCurrentDesign().navBarDeselectedColor,
    splashScreenBackgroundColor:
      designManager.getCurrentDesign().splashScreenBackgroundColor,
    buttonBackgroundColor:
      designManager.getCurrentDesign().buttonBackgroundColor,
    buttonFontColor: designManager.getCurrentDesign().buttonFontColor,
    loyaltyCardBackgroundColor:
      designManager.getCurrentDesign().loyaltyCardBackgroundColor,
    loyaltyCardTextColor: designManager.getCurrentDesign().loyaltyCardTextColor,
    rewardSchemeTextColor:
      designManager.getCurrentDesign().rewardSchemeTextColor,
    rewardSchemeStampBorderColor:
      designManager.getCurrentDesign().rewardSchemeStampBorderColor,
    rewardSchemePanelBackgroundColor:
      designManager.getCurrentDesign().rewardSchemePanelBackgroundColor,
  });

  const handleColorChange = (contentProperty, newProperty) => {
    console.log(`[UNSAVED] Changed '${contentProperty}' to:`, newProperty);
    designManager.setUnsavedChanges({ [contentProperty]: newProperty });

    setColorsContent((prevContent) => ({
      ...prevContent,
      [contentProperty]: newProperty, // Dynamically update the property
    }));
  };

  const handleSave = () => {
    if (designManager.hasUnsavedChanges()) {
      const updatedDesign = designManager.getUnsavedDesign();
      const requestBody = {
        CompanyId: designManager.getCompanyId(),
        AppHeaderBackgroundColour: updatedDesign.appHeaderBackgroundColor,
        AppHeaderFontColour: updatedDesign.appHeaderFontColor,
        PrimaryBrandBackgroundColour: updatedDesign.primaryBackgroundColor,
        PrimaryBrandFontColour: updatedDesign.primaryFontColor,
        NavigationMenuBackgroundColour: updatedDesign.navBarBackgroundColor,
        NavigationMenuSelectedColour: updatedDesign.navBarSelectedColor,
        NavigationMenuDeselectedColour: updatedDesign.navBarDeselectedColor,
        SplashScreenBackgroundColour: updatedDesign.splashScreenBackgroundColor,
        ButtonsBackgroundColour: updatedDesign.buttonBackgroundColor,
        ButtonsFontColour: updatedDesign.buttonFontColor,
        DigitalLoyaltyCardBackgroundColour:
          updatedDesign.loyaltyCardBackgroundColor,
        DigitalLoyaltyCardTextColour: updatedDesign.loyaltyCardTextColor,
        RewardSchemeTextColour: updatedDesign.rewardSchemeTextColor,
        RewardSchemeStampBorderColour:
          updatedDesign.rewardSchemeStampBorderColor,
        RewardSchemePanelBackgroundColour:
          updatedDesign.rewardSchemePanelBackgroundColor,
      };
      console.log("Calling SaveColorsTheme API:", requestBody);
      const response = ApiServiceClass.SaveColorsTheme(requestBody)
        .then(() => {
          designManager.updateDesign(updatedDesign);
          toast.success("Colors Theme Saved", {
            autoClose: 1200,
          });
        })
        .catch((error) => {
          console.error("Error SaveColorsTheme: ", error);
          toast.error("Error SaveColorsTheme", {
            autoClose: 1500,
          });
        });
    } else {
      console.log("No changes to save.");
    }
  };

  const handleReset = () => {
    console.log("Resetting design");
    designManager.resetUnsavedChanges();

    setColorsContent({
      appHeaderBackgroundColor:
        designManager.getCurrentDesign().appHeaderBackgroundColor,
      appHeaderFontColor: designManager.getCurrentDesign().appHeaderFontColor,
      primaryBackgroundColor:
        designManager.getCurrentDesign().primaryBackgroundColor,
      primaryFontColor: designManager.getCurrentDesign().primaryFontColor,
      navBarBackgroundColor:
        designManager.getCurrentDesign().navBarBackgroundColor,
      navBarSelectedColor: designManager.getCurrentDesign().navBarSelectedColor,
      navBarDeselectedColor:
        designManager.getCurrentDesign().navBarDeselectedColor,
      splashScreenBackgroundColor:
        designManager.getCurrentDesign().splashScreenBackgroundColor,
      buttonBackgroundColor:
        designManager.getCurrentDesign().buttonBackgroundColor,
      buttonFontColor: designManager.getCurrentDesign().buttonFontColor,
      loyaltyCardBackgroundColor:
        designManager.getCurrentDesign().loyaltyCardBackgroundColor,
      loyaltyCardTextColor:
        designManager.getCurrentDesign().loyaltyCardTextColor,
      rewardSchemeTextColor:
        designManager.getCurrentDesign().rewardSchemeTextColor,
      rewardSchemeStampBorderColor:
        designManager.getCurrentDesign().rewardSchemeStampBorderColor,
      rewardSchemePanelBackgroundColor:
        designManager.getCurrentDesign().rewardSchemePanelBackgroundColor,
    });
  };

  return (
    <div style={Styles.panel}>
      <div style={Styles.contentWrapper}>
        <div style={Styles.sectionTitle}>App Header</div>
        <div style={Styles.contentGrid}>
          <div style={Styles.gridItemLeft}>
            Background Colour
            <ColourBox
              initColor={colorsContent.appHeaderBackgroundColor} // Access from colors object
              onColorChange={(newColor) =>
                handleColorChange("appHeaderBackgroundColor", newColor)
              }
            />
          </div>
          <div style={Styles.gridItemRight}>
            Font Colour
            <ColourBox
              initColor={colorsContent.appHeaderFontColor} // Access from colors object
              onColorChange={(newColor) =>
                handleColorChange("appHeaderFontColor", newColor)
              }
            />
          </div>
        </div>
        <div style={Styles.sectionTitle}>Primary Brand</div>
        <div style={Styles.contentGrid}>
          <div style={Styles.gridItemLeft}>
            Background Colour
            <ColourBox
              initColor={colorsContent.primaryBackgroundColor} // Access from colors object
              onColorChange={(newColor) =>
                handleColorChange("primaryBackgroundColor", newColor)
              }
            />
          </div>
          <div style={Styles.gridItemRight}>
            Font Colour
            <ColourBox
              initColor={colorsContent.primaryFontColor} // Access from colors object
              onColorChange={(newColor) =>
                handleColorChange("primaryFontColor", newColor)
              }
            />
          </div>
        </div>

        <div style={Styles.sectionTitle}>Navigation Menu</div>
        <div style={Styles.contentGrid}>
          <div style={Styles.gridItemLeft}>
            Background Colour
            <ColourBox
              initColor={colorsContent.navBarBackgroundColor} // Access from colors object
              onColorChange={(newColor) =>
                handleColorChange("navBarBackgroundColor", newColor)
              }
            />
          </div>
          <div style={Styles.gridItemRight}>
            Selected Colour
            <ColourBox
              initColor={colorsContent.navBarSelectedColor} // Access from colors object
              onColorChange={(newColor) =>
                handleColorChange("navBarSelectedColor", newColor)
              }
            />
          </div>
          <div style={Styles.gridItemLeft}>
            Deselected Colour
            <ColourBox
              initColor={colorsContent.navBarDeselectedColor} // Access from colors object
              onColorChange={(newColor) =>
                handleColorChange("navBarDeselectedColor", newColor)
              }
            />
          </div>
        </div>

        <div style={Styles.sectionTitle}>Splash Screen</div>
        <div style={Styles.contentGrid}>
          <div style={Styles.gridItemLeft}>
            Background Colour
            <ColourBox
              initColor={colorsContent.splashScreenBackgroundColor} // Access from colors object
              onColorChange={(newColor) =>
                handleColorChange("splashScreenBackgroundColor", newColor)
              }
            />
          </div>
        </div>

        <div style={Styles.sectionTitle}>Buttons</div>
        <div style={Styles.contentGrid}>
          <div style={Styles.gridItemLeft}>
            Background Colour
            <ColourBox
              initColor={colorsContent.buttonBackgroundColor} // Access from colors object
              onColorChange={(newColor) =>
                handleColorChange("buttonBackgroundColor", newColor)
              }
            />
          </div>
          <div style={Styles.gridItemRight}>
            Font Colour
            <ColourBox
              initColor={colorsContent.buttonFontColor} // Access from colors object
              onColorChange={(newColor) =>
                handleColorChange("buttonFontColor", newColor)
              }
            />
          </div>
        </div>

        <div style={Styles.sectionTitle}>Digital Loyalty Card</div>
        <div style={Styles.contentGrid}>
          <div style={Styles.gridItemLeft}>
            Background Colour
            <ColourBox
              initColor={colorsContent.loyaltyCardBackgroundColor} // Access from colors object
              onColorChange={(newColor) =>
                handleColorChange("loyaltyCardBackgroundColor", newColor)
              }
            />
          </div>
          <div style={Styles.gridItemRight}>
            Text Colour
            <ColourBox
              initColor={colorsContent.loyaltyCardTextColor} // Access from colors object
              onColorChange={(newColor) =>
                handleColorChange("loyaltyCardTextColor", newColor)
              }
            />
          </div>
        </div>
        <div style={Styles.sectionTitle}>Reward Scheme</div>
        <div style={Styles.contentGrid}>
          <div style={Styles.gridItemLeft}>
            Text Colour
            <ColourBox
              initColor={colorsContent.rewardSchemeTextColor} // Access from colors object
              onColorChange={(newColor) =>
                handleColorChange("rewardSchemeTextColor", newColor)
              }
            />
          </div>
          <div style={Styles.gridItemRight}>
            Stamp Border Colour
            <ColourBox
              initColor={colorsContent.rewardSchemeStampBorderColor} // Access from colors object
              onColorChange={(newColor) =>
                handleColorChange("rewardSchemeStampBorderColor", newColor)
              }
            />
          </div>

          <div style={Styles.gridItemLeft}>
            Panel Background Colour
            <ColourBox
              initColor={colorsContent.rewardSchemePanelBackgroundColor} // Access from colors object
              onColorChange={(newColor) =>
                handleColorChange("rewardSchemePanelBackgroundColor", newColor)
              }
            />
          </div>
        </div>
      </div>
      <div style={Styles.actions}>
        <button style={Styles.resetBtn} onClick={handleReset}>
          Reset
          <Tooltip
            title={
              <span style={{ display: "block", textAlign: "center" }}>
                This will reset all content to the last saved values
              </span>
            }
            arrow
          >
            <HelpOutlineIcon
              style={{
                marginLeft: "4px",
                fontSize: "17px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </button>
        <button style={Styles.applyBtn} onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
}

export default ColorsContent;
