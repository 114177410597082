import React, { useState } from "react";
import { Button } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import Styles from "../resources/styles";
import ImageBox from "./sharedcomponents/ImageBox";
import { toast } from "react-toastify";
import { ApiServiceClass } from "../../../components/api-service";

function LogoContent({ designManager }) {
  const [logoContent, setLogoContent] = useState({
    companyLogo: designManager.getCurrentDesign().companyLogo,
  });

  const handleReset = () => {
    console.log("Resetting design");
    designManager.resetUnsavedChanges();
    setLogoContent({
      companyLogo: designManager.getCurrentDesign().companyLogo,
    });
  };

  const handleImageChange = (
    newLogo,
    newFileName,
    newFileSize,
    newFileType
  ) => {
    console.log("Changing companyLogo to: ", newLogo);
    designManager.setUnsavedChanges({ ["companyLogo"]: newLogo });
    designManager.setUnsavedChanges({ ["companyLogoFileName"]: newFileName });
    designManager.setUnsavedChanges({ ["companyLogoFileSize"]: newFileSize });
    designManager.setUnsavedChanges({ ["companyLogoFileType"]: newFileType });
    setLogoContent({ companyLogo: newLogo });
  };

  const handleSave = () => {
    if (designManager.hasUnsavedChanges()) {
      const updatedDesign = designManager.getUnsavedDesign();
      const requestBody = {
        CompanyId: designManager.getCompanyId(),
        companyLogo: updatedDesign.companyLogo,
        CompanyLogoFileType: updatedDesign.companyLogoFileType,
        RequesterUserId: `${designManager.getUserId()}`, // User ID in quotes
      };
      console.log("Calling SaveCompanyLogo API:", requestBody);
      const response = ApiServiceClass.SaveCompanyLogo(requestBody)
        .then(() => {
          designManager.updateDesign(updatedDesign);
          toast.success("Company Logo Saved", {
            autoClose: 1200,
          });
        })
        .catch((error) => {
          console.error("Error Saving Company Logo: ", error);
          toast.error("Error Saving Company Logo", {
            autoClose: 1500,
          });
        });
    } else {
      console.log("No changes to save.");
    }
  };

  return (
    <div style={Styles.panel}>
      <div style={Styles.contentWrapper}>
        <div style={Styles.sectionTitle}>Image</div>
        <div style={Styles.content}>
          <ImageBox
            initialImage={logoContent.companyLogo}
            fileName={designManager.getCurrentDesign().companyLogoFileName}
            fileSize={designManager.getCurrentDesign().companyLogoFileSize}
            onImageChange={(newImage, newFileName, newFileSize, newFileType) =>
              handleImageChange(newImage, newFileName, newFileSize, newFileType)
            }
          />
        </div>
      </div>
      <div style={Styles.actions}>
        <button style={Styles.resetBtn} onClick={handleReset}>
          Reset
          <Tooltip
            title={
              <span style={{ display: "block", textAlign: "center" }}>
                This will reset all content to the last saved values
              </span>
            }
            arrow
          >
            <HelpOutlineIcon
              style={{
                marginLeft: "4px",
                fontSize: "17px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </button>
        <button style={Styles.applyBtn} onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
}

export default LogoContent;
