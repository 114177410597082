import React, { useEffect, useState } from "react";
import Styles from "../resources/styles";
import FontBox from "./sharedcomponents/FontBox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import { ApiServiceClass } from "../../../components/api-service";
import { toast } from "react-toastify";

function FontsContent({ fonts, designManager }) {
  //console.log(fonts);
  const [fontContent, setFontContent] = useState({
    appHeaderFont: designManager.getCurrentDesign().appHeaderFont,
    primaryThemeFont: designManager.getCurrentDesign().primaryThemeFont,
    buttonsFont: designManager.getCurrentDesign().buttonsFont,
  });

  const handleFontChange = (fontProperty, newFont) => {
    console.log(`Changing '${fontProperty}' to`, newFont);
    designManager.setUnsavedChanges({ [fontProperty]: newFont });

    // Update local state for live preview
    setFontContent((prevState) => ({
      ...prevState,
      [fontProperty]: newFont,
    }));
  };

  const getFontIdByTechnicalName = (technicalName) => {
    const font = fonts.find((font) => font.FontTechnicalName === technicalName);
    return font ? font.Id : null; // Return the Id if found, otherwise return null
  };

  const handleSave = () => {
    if (designManager.hasUnsavedChanges()) {
      const updatedDesign = designManager.getUnsavedDesign();
      const requestBody = {
        CompanyId: designManager.getCompanyId(),
        AppHeaderFont: getFontIdByTechnicalName(updatedDesign.appHeaderFont),
        PrimaryFont: getFontIdByTechnicalName(updatedDesign.primaryThemeFont),
        ButtonsFont: getFontIdByTechnicalName(updatedDesign.buttonsFont),
      };
      console.log("Calling SaveFontsTheme API:", requestBody);
      ApiServiceClass.SaveFontsTheme(requestBody)
        .then(() => {
          designManager.updateDesign(updatedDesign);
          toast.success("Fonts Theme Saved", {
            autoClose: 1200,
          });
        })
        .catch((error) => {
          console.error("Error Saving Font Theme: ", error);
          toast.error("Error Saving Font Theme", {
            autoClose: 1500,
          });
        });
    } else {
      console.log("No changes to save.");
    }
  };

  const handleReset = () => {
    console.log("Resetting design");
    designManager.resetUnsavedChanges();

    // Update state with reset values
    setFontContent({
      appHeaderFont: designManager.getCurrentDesign().appHeaderFont,
      primaryThemeFont: designManager.getCurrentDesign().primaryThemeFont,
      buttonsFont: designManager.getCurrentDesign().buttonsFont,
    });
  };

  return (
    <div style={Styles.panel}>
      <div style={Styles.contentWrapper}>
        <div style={Styles.sectionTitle}>App Header</div>
        <div style={Styles.content}>
          Font style
          <FontBox
            fonts={fonts}
            selectedFont={fontContent.appHeaderFont} // Pass selectedFont
            onFontChange={(newFont) =>
              handleFontChange("appHeaderFont", newFont)
            }
          />
        </div>

        <div style={Styles.sectionTitle}>Primary Font Theme</div>
        <div style={Styles.content}>
          Font Style
          <FontBox
            fonts={fonts}
            selectedFont={fontContent.primaryThemeFont} // Pass selectedFont
            onFontChange={(newFont) =>
              handleFontChange("primaryThemeFont", newFont)
            }
          />
        </div>
        <div style={Styles.sectionTitle}>Buttons</div>
        <div style={Styles.content}>
          Font Style
          <FontBox
            fonts={fonts}
            selectedFont={fontContent.buttonsFont} // Pass selectedFont
            onFontChange={(newFont) => handleFontChange("buttonsFont", newFont)}
          />
        </div>
      </div>
      <div style={Styles.actions}>
        <button style={Styles.resetBtn} onClick={handleReset}>
          Reset
          <Tooltip
            title={
              <span style={{ display: "block", textAlign: "center" }}>
                This will reset all content to the last saved values
              </span>
            }
            arrow
          >
            <HelpOutlineIcon
              style={{
                marginLeft: "4px",
                fontSize: "17px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </button>
        <button style={Styles.applyBtn} onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
}

export default FontsContent;
