import React, { useState } from "react";
import { ApiServiceClass } from "./api-service.js";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  SvgIcon,
} from "@mui/material";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";

export const CancelPushNotification = (props) => {
  const { notification, companyId, fetchData } = props;

  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleOpen = () => setDialogOpen(true);
  const handleClose = () => setDialogOpen(false);

  const handleCancelNotification = async (notif) => {
    const requestBody = {
      CompanyId: companyId,
      GroupId: notif.GroupId,
    };

    console.log("handleCancelNotification payload:", requestBody);

    try {
      const response = await ApiServiceClass.CancelPushNotification(requestBody);

      if (response.status >= 200 && response.status < 300) {
        toast.success("Push notification cancelled successfully.");
        // Optionally close dialog and refresh data
        handleClose();
        if (props.fetchData) props.fetchData();
      } else {
        console.error("Server responded with an error:", response);
        toast.error("An error occurred while cancelling the push notification.");
      }
    } catch (error) {
      console.error("Network or server error occurred:", error);
      toast.error("An error occurred while cancelling the push notification.");
    }

    fetchData();
  };

  const styles = {
    deleteButton: {
      cursor: "pointer",
      marginRight: "0px",
      backgroundColor: "#BB1B16",
    },
    dialogPaper: {
      borderRadius: "5px",
      width: "400px",
    },
    dialogTitle: {
      paddingBottom: "5px",
      textAlign: "center",
    },
    dialogContent: {
      paddingBottom: "15px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    dialogActions: {
      paddingBottom: "25px",
      alignItems: "center",
      justifyContent: "center",
    },
    yesButton: {
      width: "30%",
      marginRight: "35px",
      borderRadius: "5px",
    },
    noButton: {
      width: "30%",
      borderRadius: "5px",
    },
  };

  return (
    <>
      <Button
        startIcon={
          <SvgIcon fontSize="small">
            <DeleteIcon />
          </SvgIcon>
        }
        variant="contained"
        size="xs"
        onClick={handleOpen}
        style={styles.deleteButton}
      >
        Delete
      </Button>

      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        PaperProps={{ style: styles.dialogPaper }}
      >
        <DialogTitle style={styles.dialogTitle}>
          Cancel Push Notification
        </DialogTitle>
        <DialogContent style={styles.dialogContent}>
          Are you sure you want to cancel this notification group?
        </DialogContent>
        <DialogActions style={styles.dialogActions}>
          <Button
            onClick={() => handleCancelNotification(notification)}
            color="primary"
            style={styles.yesButton}
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            color="primary"
            style={styles.noButton}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

CancelPushNotification.propTypes = {
  notification: PropTypes.shape({
    GroupId: PropTypes.number.isRequired,
  }).isRequired,
  companyId: PropTypes.number.isRequired,
  fetchData: PropTypes.func, // Optional, for refreshing data after cancellation
};
