import React from "react";
import { Typography } from "@mui/material";
import Icon from "../resources/box_dialog.svg"; // Adjust the path as needed

const OffersContent = ({ designManager }) => {
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "40px",
          display: "flex", // Enable Flexbox
          alignItems: "center", // Vertically center
          justifyContent: "center", // Horizontally center
          backgroundColor: designManager.getFieldValue(
            "appHeaderBackgroundColor"
          ),
          textAlign: "center",
          marginTop: "15px",
          color: designManager.getFieldValue("appHeaderFontColor"),
          fontSize: "14px",
          fontFamily: designManager.getFieldValue("appHeaderFont"),
          zIndex: 2,
        }}
      >
        Offers
      </div>
      <div
        style={{
          width: "100%",
          height: "500px",
          backgroundColor: designManager.getFieldValue(
            "primaryBackgroundColor"
          ),
          color: designManager.getFieldValue("primaryFontColor"),
          fontSize: "14px",
          fontFamily: designManager.getFieldValue("primaryThemeFont"),
          display: "flex", // Flexbox for centering
          flexDirection: "column", // Arrange content vertically
          justifyContent: "center", // Center vertically
          alignItems: "center", // Center horizontally
        }}
      >
        <img
          src={Icon}
          alt="No offers icon"
          style={{
            width: "120px",
            height: "120px",
            marginBottom: "16px",
            marginTop: "-30px",
          }}
        />
        {/* "No offers yet" text */}
        <div style={{ fontSize: "14px", fontWeight: "bold" }}>
          No offers yet
        </div>
        <div style={{ fontSize: "12px" }}>Check back later for new offers</div>
      </div>
    </div>
  );
};

export default OffersContent;
