import React from "react";
import barcode from "../../../tools/pngs/barcode.png";
import Base64Image from "../cards/sharedcomponents/Base64Image";

const CardContent = ({ designManager }) => {
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "40px",
          display: "flex", // Enable Flexbox
          alignItems: "center", // Vertically center
          justifyContent: "center", // Horizontally center
          backgroundColor: designManager.getFieldValue(
            "appHeaderBackgroundColor"
          ),
          color: designManager.getFieldValue("appHeaderFontColor"),
          textAlign: "center",
          marginTop: "15px",
          fontSize: "14px",
          fontFamily: designManager.getFieldValue("appHeaderFont"),
          zIndex: 2,
        }}
      >
        Loyalty Card
      </div>
      <div
        style={{
          width: "100%",
          height: "500px",
          backgroundColor: designManager.getFieldValue(
            "primaryBackgroundColor"
          ),

          display: "flex", // Flexbox for centering
          flexDirection: "column", // Arrange content vertically
        }}
      >
        <div
          style={{
            width: "91%",
            height: "165px",
            backgroundColor: designManager.getFieldValue(
              "loyaltyCardBackgroundColor"
            ),
            borderRadius: "12px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "25px",
            marginLeft: "10px",
            boxShadow: "0 6px 10px rgba(0, 0, 0, 0.70)", // Add box shadow here
          }}
        >
          {/* New container for the icon and name */}
          <div
            style={{
              width: "80%",
              flexDirection: "column",
              marginLeft: "-30px",
              marginTop: "-12px",
            }}
          >
            <Base64Image
              image={designManager.getFieldValue("companyLogo")}
              style={{
                width: "55px",
                height: "auto", // Ensures the aspect ratio is maintained
                maxHeight: "50px", // Limit the maximum height
                objectFit: "contain",
              }}
            />

            <div
              style={{
                fontSize: "12px", // Adjust font size as needed
                color: designManager.getFieldValue("loyaltyCardTextColor"),
                zIndex: 34,
                marginTop: "-5px",
              }}
            >
              John Doe
            </div>
          </div>

          <div
            style={{
              width: "92%",
              height: "56px",
              marginTop: "5px", // Adjusted for spacing
              display: "flex",
              flexDirection: "column",
              alignItems: "center", // Center vertically
              backgroundColor: "#ffffff", // White background behind barcode + number
              borderRadius: "8px",
              padding: "2px 2px", // Reduced padding to make it shorter
              zIndex: 2,
            }}
          >
            <img
              src={barcode}
              alt="Barcode"
              style={{
                width: "92%",
                height: "auto",
              }}
            />

            <div
              style={{
                fontSize: "10px",
                color: designManager.getFieldValue("loyaltyCardTextColor"),
                textAlign: "center",
                marginTop: "6px", // Adjusted for spacing
              }}
            >
              80837594791
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: "20px",
            fontSize: "14px",
            color: "#000000",
            textAlign: "center",
            fontFamily: designManager.getFieldValue("primaryThemeFont"),
          }}
        >
          How to use my card
        </div>
      </div>
    </div>
  );
};

export default CardContent;
