import React, { useEffect, useState } from "react";
import Styles from "../resources/styles";
import ColourBox from "./sharedcomponents/ColourBox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import { ApiServiceClass } from "../../api-service";
import { toast } from "react-toastify";

function NavigationContent({ designManager }) {
  const [navigationContent, setNavigationContent] = useState({
    navBarBackgroundColor:
      designManager.getCurrentDesign().navBarBackgroundColor,
    navBarSelectedColor: designManager.getCurrentDesign().navBarSelectedColor,
    navBarDeselectedColor:
      designManager.getCurrentDesign().navBarDeselectedColor,
  });

  const handleColorChange = (contentProperty, newProperty) => {
    console.log(`[UNSAVED] Changed '${contentProperty}' to:`, newProperty);
    designManager.setUnsavedChanges({ [contentProperty]: newProperty });

    setNavigationContent((prevContent) => ({
      ...prevContent,
      [contentProperty]: newProperty, // Dynamically update the property
    }));
  };

  const handleSave = () => {
    if (designManager.hasUnsavedChanges()) {
      const updatedDesign = designManager.getUnsavedDesign();
      const requestBody = {
        CompanyId: designManager.getCompanyId(),
        NavBarSelectedColor: updatedDesign.navBarSelectedColor,
        NavBarUnselectedColor: updatedDesign.navBarDeselectedColor,
        NavBarBackgroundColor: updatedDesign.navBarBackgroundColor,
      };
      console.log("Calling SaveMenuNavigationTheme API:", requestBody);
      const response = ApiServiceClass.SaveMenuNavigationTheme(requestBody)
        .then(() => {
          designManager.updateDesign(updatedDesign);
          toast.success("Navigation Menu Saved", {
            autoClose: 1200,
          });
        })
        .catch((error) => {
          console.error("Error Saving Navigation Menu: ", error);
          toast.error("Error Saving Navigation Menu", {
            autoClose: 1500,
          });
        });
    } else {
      console.log("No changes to save.");
    }
  };

  const handleReset = () => {
    console.log("Resetting design");
    designManager.resetUnsavedChanges();

    setNavigationContent({
      navBarBackgroundColor:
        designManager.getCurrentDesign().navBarBackgroundColor,
      navBarSelectedColor: designManager.getCurrentDesign().navBarSelectedColor,
      navBarDeselectedColor:
        designManager.getCurrentDesign().navBarDeselectedColor,
    });
  };

  return (
    <div style={Styles.panel}>
      <div style={Styles.contentWrapper}>
        <div style={Styles.sectionTitle}>Colours</div>
        <div style={Styles.contentGrid}>
          <div style={Styles.gridItemLeft}>
            Background Colour
            <ColourBox
              initColor={navigationContent.navBarBackgroundColor} // Access from colors object
              onColorChange={(newColor) =>
                handleColorChange("navBarBackgroundColor", newColor)
              }
            />
          </div>
          <div style={Styles.gridItemRight}>
            Selected Colour
            <ColourBox
              initColor={navigationContent.navBarSelectedColor}
              onColorChange={(newColor) =>
                handleColorChange("navBarSelectedColor", newColor)
              }
            />
          </div>
          <div style={Styles.gridItemLeft}>
            Deselected Colour
            <ColourBox
              initColor={navigationContent.navBarDeselectedColor}
              onColorChange={(newColor) =>
                handleColorChange("navBarDeselectedColor", newColor)
              }
            />
          </div>
        </div>
      </div>
      <div style={Styles.actions}>
        <button style={Styles.resetBtn} onClick={handleReset}>
          Reset
          <Tooltip
            title={
              <span style={{ display: "block", textAlign: "center" }}>
                This will reset all content to the last saved values
              </span>
            }
            arrow
          >
            <HelpOutlineIcon
              style={{
                marginLeft: "4px",
                fontSize: "17px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </button>
        <button style={Styles.applyBtn} onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
}

export default NavigationContent;
